$primary: #8c0000;/* MAIN COLOR */
$secondary: #ffdcdc; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fff;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;



@import url('https://fonts.googleapis.com/css?family=Lobster|Quattrocento+Sans');
// font-family: 'Lobster', cursive;
// font-family: 'Quattrocento Sans', sans-serif;


h2, h3{
    font-family: 'Lobster', cursive;
}

p {
    font-family: 'Quattrocento Sans', sans-serif;
}

html, body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: $secondary;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 25px;
            display: flex;
            align-items: center;
            color: $blk;
            transition-duration: 0.50s;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                border-radius: $border-radius;
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        color: #000;
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 50%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 40px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 225px;
    padding: 1.0em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 10px;
    }
}

.top-pad {
    padding: 3em;
    background: white;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 15px;
    }
    
    & ol {
        li {
            font-size: 1em;
        }
        & ol li {
            font-size: 0.75em;
        }
    }
}

@media (max-width: 767px){
    #signUpForm{
         padding: 5px 10px !important;    
    }
}


// .intro{
//    background: url(../img/banner.jpg);
//    background-repeat: no-repeat;
//    background-size: cover;
//    background-attachment: unset;

//    background-position: 50% 20%;
//    padding: 400px 0px;

//     // @media (max-width: ){

//     // }

//     @media (max-width: 767px) {
//       background-position: 50% 20%;
//       padding: 150px 0px; 
//     } 
// }

.divider{
    background-color: #333;
    //background-image: url(../img/starring.png);
    background-image: url(../img/hearts2.png);
    padding: 150px 0px;

    @media (max-width: 767px) {
       padding: 50px 0px; 
    }

    h1 {
        font-family: 'Lobster', cursive;
        color: $wht;
            text-shadow: 2px 2px #666;
    }

}

.form {

    h1 {
        font-family: 'Lobster', cursive;
        text-shadow: 2px 2px #666;
    }
    
    p {
      font-family: 'Quattrocento Sans', sans-serif;
      font-size: 25px;  

      @media (max-width: 1199px) {
          font-size: 23px;
      }

      @media (max-width: 991px) {
          font-size: 20px;
      }

       @media (max-width: 767px) {
          font-size: 15px;
      }
    }
}

.outro{
    background: url(../img/banner2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 20%;
    padding: 350px 0px;

    @media (max-width: 767px) {
       background-position: 20% 20%;
       padding: 150px 0px; 
    } 
}


.btn {
  font-family: 'Quattrocento Sans', sans-serif;
  background-color: $primary;
  // border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 1em 2em;
  color: $wht;

    &:hover {
        background-color: $secondary; 
        //border-color: rgba(255,255,255,1); 
        color: $blk;

        @media (max-width: 1366px) {
            background-color: $primary;
            color: $wht;
        }

    &:active, &:focus {
        background-color: $primary;
        color: $wht !important;
        }
    }
}



.parallax {
	text-align:center;
	background-image: url(../img/banner.jpg);
	background-position: 50% 0px;
	background-repeat:no-repeat;
	background-size:cover;
	background-attachment:fixed;
	overflow:hidden;

    @media (max-width: 1366px) and (orientation:landscape){
     background-image: url(../img/banner.jpg);
     background-repeat:no-repeat;
     background-size:cover;
     background-position:50% 50%;
     background-attachment: unset;
     height: 650px
    }

    @media (max-width: 1024px){
     background-image: url(../img/banner.jpg);
     background-repeat:no-repeat;
     background-size:cover;
     background-position: 50% 50% !important;
     background-attachment: unset;
     height: 550px
    }


    @media (max-width: 1024px) and (orientation:landscape){
     background-image: url(../img/banner.jpg);
     background-repeat:no-repeat;
     background-size:cover;
     background-position:50% 50%;
     background-attachment: unset;
     height: 650px
    }

    @media (max-width: 768px){
     background-image: url(../img/banner.jpg);
     background-repeat:no-repeat;
     background-size:cover;
     background-position: 50% 50% !important;
     background-attachment: unset;
     height: 550px
    }

	@media (max-width: 767px){
	 background-image: url(../img/banner.jpg);
	 background-repeat:no-repeat;
	 background-size:cover;
	 background-position: 50% 50% !important;
	 background-attachment: unset;
	 height: 400px
	}

	@media (max-width: 568px) and (orientation:landscape){
	 background-image: url(../img/banner.jpg);
	 background-repeat:no-repeat;
	 background-size:cover;
	 background-position:50% 50%;
	 background-attachment: unset;
	 height: 300px
	}

	@media (max-width: 320px){
	 background-image: url(../img/banner.jpg);
	 background-repeat:no-repeat;
	 background-size:cover;
	 background-position: 50% 50% !important;
	 background-attachment: unset;
	 height: 300px
	}

}

.parallax-pattern-overlay {
	background-image:url(../img/pattern.png);
	background-repeat: repeat;
}